<template>
    <h1>
      {{getHeaderText}}
  </h1>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'header-text',
    	props: {
    		name: String,
    		headline: String
    	},
    	computed: {
    		getHeaderText: function() {
    			var headerText = '';
    			if (this.headline) {
    				headerText = this.headline;
    			} else {
    				headerText = this.name;
    			}
    			return headerText;
    		}
    	}
    };
</script>